import React from "react";

import IdeaImage from "../assets/images/idea.png";
import DrawIcon from "../assets/images/draw.png";
import ToolsImage from "../assets/images/tools.png";
import ArrowWhite from "../assets/images/arrow-white.png";

const Process = () => {
  return (
    <div className="bg-zinc-900 md:flex md:flex-row sm:flex-column items-center py-4 px-4">
      <div className="basis-1/3 h-80 rounded-xl bg-zinc-100 p-4">
        <img className="w-14 h-14" src={IdeaImage} alt="" />
        <p className="text-center mb-6 lg:mb-8 text-2xl lg:text-3xl font-bold">Pomysł</p>
        <p className="text-md lg:text-lg">
          Chętnie dzielimy się naszym doświadczeniem oraz jesteśmy otwarci na wszelkie sugestie i uwagi dotyczące
          Twojego projektu
        </p>
      </div>
      <img className="hidden md:block w-14 h-14" src={ArrowWhite} alt="" />
      <div className="basis-1/3 h-80 rounded-xl bg-zinc-100 p-4 mt-4 md:mt-0">
        <img className="w-12 h-12" src={DrawIcon} alt="" />
        <p className="text-center mb-6 lg:mb-8 text-2xl lg:text-3xl font-bold">Projekt</p>
        <p className="text-md lg:text-lg">
          Przed montażem wykonujemy wizualizację 3D konstrukcji. Na tym etapie możesz nanieść poprawki i sprawdzić jak w
          rzeczywistości będzie wyglądał Twój projekt
        </p>
      </div>
      <img className="hidden md:block w-14 h-14" src={ArrowWhite} alt="" />
      <div className="basis-1/3 h-80 rounded-xl bg-zinc-100 p-4 mt-4 md:mt-0">
        <img className="w-12 h-12" src={ToolsImage} alt="" />
        <p className="text-center mb-6 lg:mb-8 text-2xl lg:text-3xl font-bold">Montaż</p>
        <p className="text-md lg:text-lg">
          Po ustaleniu wszystkich szczegółów zaczynamy prace montażowe. W ramach realizacji istnieje możliwość wykonania
          przez naszą firmę betonowych fundamentów punktowych
        </p>
      </div>
    </div>
  );
};

export default Process;
