import React from "react";
import { useNavigate } from "react-router-dom";

import realization6 from "src/assets/images/kuchnia 1/1.jpeg";
import realization7 from "src/assets/images/kuchnia bozenka/1.jpeg";
import realization12 from "src/assets/images/szafa/1.jpeg";

const RealizationsMeble: React.FC = () => {
  const navigate = useNavigate();
  const images: [string, number][] = [
    [realization6, 6],
    [realization7, 7],
    [realization12, 12],
  ];

  return (
    <div className="pb-12 mx-4">
      <p className="text-xl font-semibold subpixel-antialiased mb-4">Przykładowe realizacje</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {images.map((image, index) => {
          const [imageSrc, imageId] = image;
          return (
            <div
              className="cursor-pointer aspect-w-1 aspect-h-1 overflow-hidden"
              key={index}
              onClick={() => navigate(`/realizationDetails/${imageId}`)}
            >
              <img
                className="rounded-lg shadow-xl object-cover w-full h-full"
                src={imageSrc}
                alt={`Gallery image ${index + 1}`}
                loading="lazy"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RealizationsMeble;
