import React from "react";
import { useNavigate } from "react-router-dom";

import realization1 from "src/assets/images/7x4 pod rolete/1.jpeg";
import realization2 from "src/assets/images/Chodecz/1.jpeg";
import realization3 from "src/assets/images/daszek wejscie/1.jpeg";
import realization4 from "src/assets/images/działki/1.jpeg";
import realization5 from "src/assets/images/elektryk/1.jpeg";
import realization8 from "src/assets/images/lamele marek/1.jpeg";
import realization9 from "src/assets/images/pitca/1.jpeg";
import realization10 from "src/assets/images/retkinia/3.jpeg";
import realization11 from "src/assets/images/rylu/1.jpeg";
import realization13 from "src/assets/images/wcislo/1.jpeg";
import realization14 from "src/assets/images/wisniowa/1.jpeg";
import realization15 from "src/assets/images/zgierz/1.jpeg";
import realization16 from "src/assets/images/Zlotno 2/1.jpeg";
import realization17 from "src/assets/images/Zlotno marek/1.jpeg";
import realization18 from "src/assets/images/zolta z hamakiem/1.jpeg";
import realization19 from "src/assets/images/zyrardow/1.jpeg";

const RealizationsAltany: React.FC = () => {
  const navigate = useNavigate();

  const images: [string, number][] = [
    [realization1, 1],
    [realization2, 2],
    [realization3, 3],
    [realization4, 4],
    [realization5, 5],
    [realization8, 8],
    [realization9, 9],
    [realization10, 10],
    [realization11, 11],
    [realization13, 13],
    [realization14, 14],
    [realization15, 15],
    [realization16, 16],
    [realization17, 17],
    [realization18, 18],
    [realization19, 19],
  ];
  return (
    <div className="pb-12 mx-4">
      <p className="text-xl font-semibold subpixel-antialiased mb-4">Przykładowe realizacje</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {images.map((image, index) => {
          const [imageSrc, imageId] = image;
          return (
            <div
              className="cursor-pointer aspect-w-1 aspect-h-1 overflow-hidden"
              key={index}
              onClick={() => navigate(`/realizationDetails/${imageId}`)}
            >
              <img
                className="rounded-lg shadow-xl object-cover w-full h-full"
                src={imageSrc}
                alt={`Gallery image ${index + 1}`}
                loading="lazy"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RealizationsAltany;
