import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Modal from "src/components/Modal";
import Gallery from "src/features/Gallery";

import realization1_1 from "src/assets/images/7x4 pod rolete/1.jpeg";
import realization1_2 from "src/assets/images/7x4 pod rolete/2.jpeg";
import realization1_3 from "src/assets/images/7x4 pod rolete/3.jpeg";
import realization1_4 from "src/assets/images/7x4 pod rolete/4.jpeg";
import realization1_5 from "src/assets/images/7x4 pod rolete/5.jpeg";
import realization1_6 from "src/assets/images/7x4 pod rolete/6.jpeg";
import realization1_7 from "src/assets/images/7x4 pod rolete/7.jpeg";
import realization2_1 from "src/assets/images/Chodecz/1.jpeg";
import realization2_2 from "src/assets/images/Chodecz/2.jpeg";
import realization2_3 from "src/assets/images/Chodecz/3.jpeg";
import realization2_4 from "src/assets/images/Chodecz/4.jpeg";
import realization2_5 from "src/assets/images/Chodecz/5.jpeg";
import realization2_6 from "src/assets/images/Chodecz/6.jpeg";
import realization3_1 from "src/assets/images/daszek wejscie/1.jpeg";
import realization3_2 from "src/assets/images/daszek wejscie/2.jpeg";
import realization4_1 from "src/assets/images/działki/1.jpeg";
import realization4_2 from "src/assets/images/działki/2.jpeg";
import realization4_3 from "src/assets/images/działki/3.jpeg";
import realization4_4 from "src/assets/images/działki/4.jpeg";
import realization5_1 from "src/assets/images/elektryk/1.jpeg";
import realization5_2 from "src/assets/images/elektryk/2.jpeg";
import realization5_3 from "src/assets/images/elektryk/3.jpeg";
import realization5_4 from "src/assets/images/elektryk/4.jpeg";
import realization5_5 from "src/assets/images/elektryk/5.jpeg";
import realization5_6 from "src/assets/images/elektryk/6.jpeg";
import realization5_8 from "src/assets/images/elektryk/8.jpeg";
import realization5_9 from "src/assets/images/elektryk/9.jpeg";
import realization5_10 from "src/assets/images/elektryk/10.jpeg";
import realization5_11 from "src/assets/images/elektryk/11.jpeg";
import realization6_1 from "src/assets/images/kuchnia 1/1.jpeg";
import realization6_2 from "src/assets/images/kuchnia 1/2.jpeg";
import realization6_3 from "src/assets/images/kuchnia 1/3.jpeg";
import realization6_7 from "src/assets/images/kuchnia 1/7.jpeg";
import realization6_9 from "src/assets/images/kuchnia 1/9.jpeg";
import realization7_1 from "src/assets/images/kuchnia bozenka/1.jpeg";
import realization7_2 from "src/assets/images/kuchnia bozenka/2.jpeg";
import realization7_3 from "src/assets/images/kuchnia bozenka/3.jpeg";
import realization7_5 from "src/assets/images/kuchnia bozenka/5.jpeg";
import realization7_6 from "src/assets/images/kuchnia bozenka/6.jpeg";
import realization8_1 from "src/assets/images/lamele marek/1.jpeg";
import realization8_2 from "src/assets/images/lamele marek/2.jpeg";
import realization8_3 from "src/assets/images/lamele marek/3.jpeg";
import realization8_4 from "src/assets/images/lamele marek/4.jpeg";
import realization8_5 from "src/assets/images/lamele marek/5.jpeg";
import realization9_1 from "src/assets/images/pitca/1.jpeg";
import realization9_2 from "src/assets/images/pitca/2.jpeg";
import realization9_3 from "src/assets/images/pitca/3.jpeg";
import realization9_4 from "src/assets/images/pitca/4.jpeg";
import realization9_5 from "src/assets/images/pitca/5.jpeg";
import realization9_6 from "src/assets/images/pitca/6.jpeg";
import realization9_7 from "src/assets/images/pitca/7.jpeg";
import realization10_2 from "src/assets/images/retkinia/2.jpeg";
import realization10_3 from "src/assets/images/retkinia/3.jpeg";
import realization10_4 from "src/assets/images/retkinia/4.jpeg";
import realization10_5 from "src/assets/images/retkinia/5.jpeg";
import realization10_6 from "src/assets/images/retkinia/6.jpeg";
import realization11_1 from "src/assets/images/rylu/1.jpeg";
import realization11_2 from "src/assets/images/rylu/2.jpeg";
import realization11_3 from "src/assets/images/rylu/3.jpeg";
import realization11_4 from "src/assets/images/rylu/4.jpeg";
import realization12_1 from "src/assets/images/szafa/1.jpeg";
import realization12_2 from "src/assets/images/szafa/2.jpeg";
import realization12_3 from "src/assets/images/szafa/3.jpeg";
import realization12_5 from "src/assets/images/szafa/5.jpeg";
import realization13_1 from "src/assets/images/wcislo/1.jpeg";
import realization13_2 from "src/assets/images/wcislo/2.jpeg";
import realization13_3 from "src/assets/images/wcislo/3.jpeg";
import realization13_4 from "src/assets/images/wcislo/4.jpeg";
import realization13_5 from "src/assets/images/wcislo/5.jpeg";
import realization13_6 from "src/assets/images/wcislo/6.jpeg";
import realization13_7 from "src/assets/images/wcislo/7.jpeg";
import realization13_9 from "src/assets/images/wcislo/9.jpeg";
import realization13_10 from "src/assets/images/wcislo/10.jpeg";
import realization13_11 from "src/assets/images/wcislo/11.jpeg";
import realization13_12 from "src/assets/images/wcislo/12.jpeg";
import realization14_1 from "src/assets/images/wisniowa/1.jpeg";
import realization14_2 from "src/assets/images/wisniowa/2.jpeg";
import realization14_3 from "src/assets/images/wisniowa/3.jpeg";
import realization14_4 from "src/assets/images/wisniowa/4.jpeg";
import realization14_5 from "src/assets/images/wisniowa/5.jpeg";
import realization14_6 from "src/assets/images/wisniowa/6.jpeg";
import realization15_1 from "src/assets/images/zgierz/1.jpeg";
import realization15_2 from "src/assets/images/zgierz/2.jpeg";
import realization15_3 from "src/assets/images/zgierz/3.jpeg";
import realization16_1 from "src/assets/images/Zlotno 2/1.jpeg";
import realization16_2 from "src/assets/images/Zlotno 2/2.jpeg";
import realization16_3 from "src/assets/images/Zlotno 2/3.jpeg";
import realization17_1 from "src/assets/images/Zlotno marek/1.jpeg";
import realization17_2 from "src/assets/images/Zlotno marek/2.jpeg";
import realization17_3 from "src/assets/images/Zlotno marek/3.jpeg";
import realization17_4 from "src/assets/images/Zlotno marek/4.jpeg";
import realization18_1 from "src/assets/images/zolta z hamakiem/1.jpeg";
import realization18_2 from "src/assets/images/zolta z hamakiem/2.jpeg";
import realization18_3 from "src/assets/images/zolta z hamakiem/3.jpeg";
import realization18_4 from "src/assets/images/zolta z hamakiem/4.jpeg";
import realization18_5 from "src/assets/images/zolta z hamakiem/5.jpeg";
import realization19_1 from "src/assets/images/zyrardow/1.jpeg";
import realization19_2 from "src/assets/images/zyrardow/2.jpeg";

const RealizationDetails: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { realizationId } = useParams();

  const realizations = {
    1: [realization1_1, realization1_2, realization1_3, realization1_4, realization1_5, realization1_6, realization1_7],
    2: [realization2_1, realization2_2, realization2_3, realization2_4, realization2_5, realization2_6],
    3: [realization3_1, realization3_2],
    4: [realization4_1, realization4_2, realization4_3, realization4_4],
    5: [
      realization5_1,
      realization5_2,
      realization5_3,
      realization5_4,
      realization5_5,
      realization5_6,
      realization5_8,
      realization5_9,
      realization5_10,
      realization5_11,
    ],
    6: [realization6_1, realization6_2, realization6_3, realization6_7, realization6_9],
    7: [realization7_1, realization7_2, realization7_3, realization7_5, realization7_6],
    8: [realization8_1, realization8_2, realization8_3, realization8_4, realization8_5],
    9: [realization9_1, realization9_2, realization9_3, realization9_4, realization9_5, realization9_6, realization9_7],
    10: [realization10_2, realization10_3, realization10_4, realization10_5, realization10_6],
    11: [realization11_1, realization11_2, realization11_3, realization11_4],
    12: [realization12_1, realization12_2, realization12_3, realization12_5],
    13: [
      realization13_1,
      realization13_2,
      realization13_3,
      realization13_4,
      realization13_5,
      realization13_6,
      realization13_7,
      realization13_9,
      realization13_10,
      realization13_11,
      realization13_12,
    ],
    14: [realization14_1, realization14_2, realization14_3, realization14_4, realization14_5, realization14_6],
    15: [realization15_1, realization15_2, realization15_3],
    16: [realization16_1, realization16_2, realization16_3],
    17: [realization17_1, realization17_2, realization17_3, realization17_4],
    18: [realization18_1, realization18_2, realization18_3, realization18_4, realization18_5],
    19: [realization19_1, realization19_2],
  };

  useEffect(() => {
    if (realizationId) {
      const images = realizations?.[realizationId];
      if (!images) return;
      setImages(realizations?.[realizationId]);
    }
  }, [realizationId]);

  useEffect(() => {
    console.log(selectedImage);
  }, [selectedImage]);

  return (
    <>
      <Gallery images={images} onImageClick={setSelectedImage} showLabel={false} />
      {selectedImage && <Modal image={selectedImage} onImageClose={() => setSelectedImage(null)} />}
    </>
  );
};

export default RealizationDetails;
