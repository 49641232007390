import React, { useState } from "react";

type Props = {
  image: string;
  onImageClose: () => void;
};

const Modal: React.FC<Props> = ({ image, onImageClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={onImageClose}>
      <div
        className="bg-white bg-opacity-90 p-4 rounded-lg w-4/5 h-[90%] flex justify-center items-center relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button onClick={onImageClose} className="absolute top-0 right-0 m-4 text-2xl font-semibold" aria-label="Close">
          <div>&times;</div>
        </button>
        <img src={image} alt="Selected" className="max-w-full max-h-full object-contain" />
      </div>
    </div>
  );
};

export default Modal;
