import React from "react";

type Props = {
  images: string[];
  onImageClick: (image: string) => void;
  showLabel?: boolean;
};

const isMobile = () => /Android|iPhone/i.test(navigator.userAgent);

const Gallery: React.FC<Props> = ({ images, onImageClick, showLabel = true }) => {
  return (
    <div className="pb-12 mx-4">
      {showLabel && <p className="text-xl font-semibold subpixel-antialiased mb-4">Przykładowe realizacje:</p>}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            className="cursor-zoom-in	sm:hover:scale-125 transition duration-500 aspect-w-1 aspect-h-1 overflow-hidden"
            key={index}
            onClick={() => !isMobile() && onImageClick(image)}
          >
            <img
              className="rounded-lg shadow-xl object-cover w-full h-full"
              src={image}
              alt={`Gallery image ${index + 1}`}
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
