import React from "react";

import Photo12 from "src/assets/images/12.jpeg";
import Photo13 from "src/assets/images/13.jpeg";

const About = () => {
  return (
    <div className="mx-3 lg:mx-3">
      <div className="md:flex md:flex-row items-center sm:flex-column mt-12">
        <div className="basis-1/2">
          <p className="text-3xl subpixel-antialiased font-bold mr-4">Z pasją i zaangażowaniem</p>
          <p className="text-xl mr-12">
            tworzymy piękne i funkcjonalne przestrzenie zadaszone, idealne do relaksu, spotkań towarzyskich oraz
            spędzania czasu na świeżym powietrzu. Nasza firma gwarantuje profesjonalną obsługę, wysoką jakość materiałów
            i wykonania oraz terminowe dostarczenie projektu.
          </p>
        </div>
        <div className="basis-1/2 mt-4 sm:mt-0 relative">
          <img src={Photo13} alt="Photo13" className="rounded-lg sm:hover:scale-125 transition duration-500" />
        </div>
      </div>
      <div className="md:flex md:flex-row items-center sm:flex-column mt-6 mb-12">
        <div className="basis-1/2">
          <p className="text-3xl sm:ml-4 subpixel-antialiased font-bold mr-4">Nasza misja to</p>
          <p className="text-xl sm:ml-4">
            dostarczanie klientom wysokiej jakości konstrukcji. Jeśli szukasz rozwiązań budowlanych, które dodadzą
            wartości Twojemu domowi, zapewnią wygodę i estetykę, to jesteśmy gotowi zrealizować Twoje potrzeby
          </p>
        </div>
        <div className="basis-1/2 mt-4 sm:mt-0 sm:order-first relative">
          <img src={Photo12} alt="Photo12" className="rounded-lg sm:hover:scale-125 transition duration-500" />
        </div>
      </div>
    </div>
  );
};

export default About;
