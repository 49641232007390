import React from "react";

const Materials = () => {
  return (
    <>
      <div className="bg-zinc-900 items-center py-2 px-4 overflow-auto">
        <p className="text-white text-xl font-bold subpixel-antialiased">
          Dostarczane przez nas konstrukcje są wykonywane z najlepszych materiałów:
        </p>
        <div className="md:flex md:flex-row sm:flex-column gap-4 py-4">
          <div className="basis-1/2 rounded-xl bg-zinc-100 px-4 py-6">
            <p className="text-left mb-6 text-3xl font-bold">Drewno KVH</p>
            <p className="text-md lg:text-lg">
              KVH to lite drewno konstrukcyjne, które przechodzi proces suszenia w specjalnych komorach suszarniczych, a
              później jest łączone za pomocą mikrowczepów, przy użyciu kleju wolnego od formaldehydów. Drewno KVH
              wyróżnia się niską wilgotnością (15-18%), stabilnymi wymiarami i gładką powierzchnią, uzyskaną dzięki
              czterostronnemu struganiu.
            </p>
          </div>
          <div className="basis-1/2 rounded-xl bg-zinc-100 px-4 py-6 mt-4 md:mt-0">
            <p className="text-left mb-6 text-3xl font-bold">Drewno BSH</p>
            <p className="text-md lg:text-lg">
              Drewno klejone ma zastosowanie w różnych dziedzinach, takich jak budownictwo i produkcja mebli, co
              sprawia, że staje się coraz bardziej popularnym materiałem do budowy i wykończenia budynków, a także do
              konstruowania różnych struktur. Popularność drewna BSH wynika z jego wyjątkowych właściwości, takich jak
              trwałość oraz wytrzymałość.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Materials;
