import React, { useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import MailIcon from "src/assets/images/mail.svg";
import HomeIcon from "src/assets/images/home.svg";
import PhoneIcon from "src/assets/images/phone.svg";
import FacebookIcon from "src/assets/images/facebook.svg";
import InstagramIcon from "src/assets/images/instagram.svg";

const Footer = () => {
  const [isSending, setIsSending] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const subjectRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const handleSendEmail = (e: any) => {
    setIsSending(true);
    e.preventDefault();
    axios
      .post("https://mail-sender.bdziedzic10.usermd.net/sendEmail", {
        subject: subjectRef.current?.value,
        emailFrom: emailRef.current?.value,
        phone: phoneRef.current?.value,
        message: messageRef.current?.value,
      })
      .then(() => {
        toast.success("Wysłano wiadomość");
        setIsSending(false);
      })
      .catch(() => {
        toast.error("Wystąpił błąd podczas wysyłania wiadomości");
        setIsSending(false);
      });
  };

  const renderForm = () => {
    return (
      <form className="space-y-4" onSubmit={handleSendEmail}>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-300">Twój adres email</label>
          <input
            type="email"
            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light"
            placeholder="nazwa@gmail.com"
            required
            ref={emailRef}
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-300">Twój numer telefonu</label>
          <input
            type="text"
            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light"
            placeholder="555-555-555"
            required
            ref={phoneRef}
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-300">Temat</label>
          <input
            type="text"
            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light"
            placeholder="Jak możemy Ci pomóc?"
            required
            ref={subjectRef}
          />
        </div>
        <div className="sm:col-span-2">
          <label className="block mb-2 text-sm font-medium text-gray-300">Twoja wiadomość</label>
          <textarea
            rows={6}
            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light"
            placeholder="Zostaw komentarz..."
            style={{ resize: "none", cursor: "pointer" }}
            required
            ref={messageRef}
          />
        </div>
        <button
          type="submit"
          className={`bg-blue-700 text-white px-4 py-2 rounded-xl ${isSending ? "animate-pulse" : undefined}`}
        >
          Wyślij wiadomość
        </button>
      </form>
    );
  };

  return (
    <section className="bg-gray-900 py-8 lg:py-16 px-4">
      <div className="mx-auto max-w-screen-md">
        <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-center text-white">
          Skontaktuj się z nami, aby omówić szczegóły i rozpocząć proces projektowania oraz budowy.
        </h2>
      </div>
      <div className="md:flex md:flex-row">
        <div className="basis-2/3">{renderForm()}</div>
        <div className="basis-1/3 ml-0 md:ml-4 mt-4 md:mt-0">
          <p className="text-white font-bold text-lg mb-4">Kontakt</p>
          <p className="mb-4 flex items-center md:justify-start text-white">
            <img src={HomeIcon} alt="mail" className="mr-3 h-5 w-5" />
            Łódź
          </p>
          <p className="mb-4 flex items-center md:justify-start text-white">
            <img src={MailIcon} alt="mail" className="mr-3 h-5 w-5" />
            zadaszeniaipergole@gmail.com
          </p>
          <p className="mb-4 flex items-center md:justify-start text-white">
            <img src={PhoneIcon} alt="mail" className="mr-3 h-5 w-5" /> 690 466 236
          </p>
          <p className="mb-4 text-white font-bold text-lg">Obserwuj nas na social mediach</p>
          <div className="flex flex-row">
            <a
              href="https://www.facebook.com/profile.php?id=100083605621070"
              target="_blank"
              className="mr-6 text-neutral-200"
              rel="noreferrer"
            >
              <img src={FacebookIcon} alt="facebook" className="h-4 w-4" />
            </a>
            <a
              href="https://instagram.com/wr_rzemioslo?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              className="mr-6 text-neutral-200"
              rel="noreferrer"
            >
              <img src={InstagramIcon} alt="instagram" className="h-4 w-4" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
