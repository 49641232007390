import React, { useState } from "react";

import About from "src/features/About";
import Offer from "src/features/Offer";
import Modal from "src/components/Modal";
import Footer from "src/features/Footer";
import Gallery from "src/features/Gallery";
import Process from "src/features/Process";
import Materials from "src/features/Materials";

import Photo1 from "src/assets/images/1.jpeg";
import Photo2 from "src/assets/images/2.jpeg";
import Photo3 from "src/assets/images/3.jpeg";
import Photo4 from "src/assets/images/4.jpeg";
import Photo5 from "src/assets/images/5.jpeg";
import Photo6 from "src/assets/images/6.jpeg";
import Photo7 from "src/assets/images/7.jpeg";
import Photo8 from "src/assets/images/8.jpeg";
import Photo9 from "src/assets/images/9.jpeg";

const Info: React.FC = () => {
  const images = [Photo1, Photo2, Photo3, Photo5, Photo6, Photo8, Photo4, Photo7, Photo9];
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <>
      <About />
      <Offer />
      <div className="my-8">
        <Process />
        <Materials />
      </div>
      <Gallery images={images} onImageClick={setSelectedImage} />
      {selectedImage && <Modal image={selectedImage} onImageClose={() => setSelectedImage(null)} />}
      <Footer />
    </>
  );
};

export default Info;
