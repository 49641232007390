import React from "react";

const Offer = () => {
  return (
    <>
      <div className="mx-4 md:mx-24">
        <p className="text-lg subpixel-antialiased text-center">
          Zapraszamy do skorzystania z naszej kompleksowej oferty budowy zadaszeń tarasu, pergoli, altan oraz wiat
          samochodowych.
        </p>
        <p className="text-lg subpixel-antialiased text-center">
          Nasza firma specjalizuje się w dostarczaniu rozwiązań budowlanych, które łączą funkcjonalność, estetykę i
          trwałość.
        </p>
      </div>
      {/* <div className="md:flex md:flex-row my-4 mx-4 md:mx-4">
        <div className="basis-1/2 space-x-4 space-y-2 mr-5">
          <p className="text-xl font-bold">Zadaszenia tarasu</p>
          <ul className="list-disc">
            <li>
              Tworzymy zadaszenia tarasu, które zapewniają ochronę przed słońcem, deszczem i innymi warunkami
              atmosferycznymi, umożliwiając przyjemne spędzanie czasu na świeżym powietrzu
            </li>
            <li>Dostosowujemy projekt do Twoich indywidualnych potrzeb i preferencji</li>
            <li>Dbamy o estetykę i funkcjonalność, tworząc przestrzeń idealną do relaksu i rozrywki</li>
          </ul>
        </div>
        <div className="basis-1/2 space-x-4 space-y-2 mt-4 md:mt-0">
          <p className="text-xl font-bold">Pergole</p>
          <ul className="list-disc">
            <li>
              Nasze pergole to eleganckie konstrukcje, które dodają uroku ogrodowi i zapewniają cień w słoneczne dni
            </li>
            <li>
              Oferujemy różne wzory i rodzaje drewna, które pozwalają dostosować pergolę do stylu Twojego ogrodu lub
              tarasu
            </li>
            <li>Pergole stanowią idealne miejsce do uprawiania roślin pnączy, co dodaje uroku i naturalnego piękna</li>
          </ul>
        </div>
      </div> */}
      {/* <div className="md:flex md:flex-row my-4 mx-4 md:mx-4">
        <div className="basis-1/2 space-x-4 space-y-2 mr-5">
          <p className="text-xl font-bold">Altany</p>
          <ul className="list-disc">
            <li>
              Budujemy altany, które są doskonałym miejscem do spotkań rodzinnych, biesiad, czy po prostu relaksu na
              świeżym powietrzu
            </li>
            <li>
              Nasze altany łączą funkcjonalność z estetyką, oferując przytulną i wygodną przestrzeń, chronioną przed
              warunkami atmosferycznymi
            </li>
            <li>
              Dostosowujemy projekt altany do Twoich potrzeb, uwzględniając wymiary, wykończenie i dodatkowe
              udogodnienia
            </li>
          </ul>
        </div>
        <div className="basis-1/2 space-x-4 space-y-2">
          <p className="text-xl font-bold">Wiata samochodowa</p>
          <ul className="list-disc">
            <li>
              Nasze wiaty samochodowe to doskonałe rozwiązanie dla tych, którzy chcą chronić swoje pojazdy przed
              warunkami atmosferycznymi
            </li>
            <li>
              Dostarczamy solidne konstrukcje, które zapewniają ochronę przed deszczem, słońcem, śniegiem i innymi
              czynnikami
            </li>
            <li>Projektujemy wiaty z myślą o estetyce, pasującej do Twojego otoczenia</li>
          </ul>
        </div>
      </div> */}
    </>
  );
};

export default Offer;
