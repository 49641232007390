import React from "react";
import { useNavigate } from "react-router-dom";

import image1 from "src/assets/images/8.jpeg";
import image2 from "src/assets/images/kuchnia 1/2.jpeg";
import { getRealizationsFirstCategoryPath, getRealizationsSecondCategoryPath } from "src/common/router/routerPaths";

const RealizationsCategories: React.FC = () => {
  const navigate = useNavigate();

  const renderFirstCategory = () => {
    return (
      <div>
        <p className="text-xl font-semibold subpixel-antialiased">Altany i zadaszenia</p>
        <div
          className="cursor-pointer sm:hover:scale-125 transition duration-500 aspect-w-1 aspect-h-1 overflow-hidden"
          onClick={() => navigate(getRealizationsFirstCategoryPath())}
        >
          <img className="rounded-lg shadow-xl object-cover w-full h-full" src={image1} loading="lazy" />
        </div>
      </div>
    );
  };

  const renderSecondCategory = () => {
    return (
      <div>
        <p className="text-xl font-semibold subpixel-antialiased">Meble na wymiar</p>
        <div
          className="cursor-pointer sm:hover:scale-125 transition duration-500 aspect-w-1 aspect-h-1 overflow-hidden"
          onClick={() => navigate(getRealizationsSecondCategoryPath())}
        >
          <img className="rounded-lg shadow-xl object-cover w-full h-full" src={image2} loading="lazy" />
        </div>
      </div>
    );
  };
  return (
    <div className="pb-12 mx-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderFirstCategory()}
        {renderSecondCategory()}
      </div>
    </div>
  );
};

export default RealizationsCategories;

// import React from "react";
// import { useNavigate } from "react-router-dom";

// import realization1 from "src/assets/images/7x4 pod rolete/1.jpeg";
// import realization2 from "src/assets/images/Chodecz/1.jpeg";
// import realization3 from "src/assets/images/daszek wejscie/1.jpeg";
// import realization4 from "src/assets/images/działki/1.jpeg";
// import realization5 from "src/assets/images/elektryk/1.jpeg";
// import realization6 from "src/assets/images/kuchnia 1/1.jpeg";
// import realization7 from "src/assets/images/kuchnia bozenka/1.jpeg";
// import realization8 from "src/assets/images/lamele marek/1.jpeg";
// import realization9 from "src/assets/images/pitca/1.jpeg";
// import realization10 from "src/assets/images/retkinia/3.jpeg";
// import realization11 from "src/assets/images/rylu/1.jpeg";
// import realization12 from "src/assets/images/szafa/1.jpeg";
// import realization13 from "src/assets/images/wcislo/1.jpeg";
// import realization14 from "src/assets/images/wisniowa/1.jpeg";
// import realization15 from "src/assets/images/zgierz/1.jpeg";
// import realization16 from "src/assets/images/Zlotno 2/1.jpeg";
// import realization17 from "src/assets/images/Zlotno marek/1.jpeg";
// import realization18 from "src/assets/images/zolta z hamakiem/1.jpeg";
// import realization19 from "src/assets/images/zyrardow/1.jpeg";

// const Realizations: React.FC = () => {
//   const navigate = useNavigate();
//   const images = [
//     realization1,
//     realization2,
//     realization3,
//     realization4,
//     realization5,
//     realization6,
//     realization7,
//     realization8,
//     realization9,
//     realization10,
//     realization11,
//     realization12,
//     realization13,
//     realization14,
//     realization15,
//     realization16,
//     realization17,
//     realization18,
//     realization19,
//   ];

//   return (
//     <div className="pb-12 mx-4">
//       <p className="text-xl font-semibold subpixel-antialiased mb-4">Przykładowe realizacje</p>
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//         {images.map((image, index) => (
//           <div
//             className="cursor-pointer aspect-w-1 aspect-h-1 overflow-hidden"
//             key={index}
//             onClick={() => navigate(`/realizationDetails/${index + 1}`)}
//           >
//             <img
//               className="rounded-lg shadow-xl object-cover w-full h-full"
//               src={image}
//               alt={`Gallery image ${index + 1}`}
//               loading="lazy"
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Realizations;
