import React from "react";
import { Outlet } from "react-router-dom";

import Header from "src/features/Header";

const HomeWrapper: React.FC = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default HomeWrapper;
