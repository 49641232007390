import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Info from "src/features/Info/Info";
import HomeWrapper from "src/features/Home/Home";
import Realizations from "src/features/Realizations/Realizations";
import RealizationsMeble from "./features/Realizations/RealizationsMeble";
import RealizationDetails from "./features/Realizations/RealizationDetails";
import RealizationsAltany from "./features/Realizations/RealizationsAltany";

import {
  getHomePath,
  getRealizationDetailsPath,
  getRealizationsPath,
  getRealizationsFirstCategoryPath,
  getRealizationsSecondCategoryPath,
  getContactPath,
} from "src/common/router/routerPaths";
import Contact from "./features/Contact/Contact";

function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HomeWrapper />}>
          <Route element={<Info />} path={getHomePath()} />
          <Route element={<Realizations />} path={getRealizationsPath()} />
          <Route element={<RealizationDetails />} path={getRealizationDetailsPath()} />
          <Route element={<RealizationsAltany />} path={getRealizationsFirstCategoryPath()} />
          <Route element={<RealizationsMeble />} path={getRealizationsSecondCategoryPath()} />
          <Route element={<Contact />} path={getContactPath()} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Root;
