import React from "react";

import Root from "./Root";

const App = () => {
  return (
    <div className="xl:mx-auto max-w-7xl">
      <div>
        <Root />
      </div>
    </div>
  );
};

export default App;
