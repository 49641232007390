import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { getRealizationsPath, getHomePath, getContactPath } from "src/common/router/routerPaths";

import LogoImage from "src/assets/images/black-oak-2.png";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <header className="flex justify-between items-center p-5 text-black relative">
      <img
        src={LogoImage}
        className="w-16 md:w-32 lg:w-32 cursor-pointer"
        alt="Logo"
        onClick={() => navigate(getHomePath())}
      />
      <nav className="flex flex-grow justify-center gap-4">
        <Link to={getRealizationsPath()}>
          <span className="font-semibold md:text-lg">Realizacje</span>
        </Link>
        <Link to={getContactPath()}>
          <span className="font-semibold md:text-lg">Kontakt</span>
        </Link>
        {/* <Link to={getRealizationsPath()}>
          <span className="font-semibold md:text-lg">Pokrycia</span>
        </Link> */}
      </nav>
    </header>
  );
};

export default Header;
